(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpUrlInput', hippoMinmaxDirective);
    hippoMinmaxDirective.$inject = ['$timeout', '$parse'];
    function hippoMinmaxDirective ($timeout, $parse) {
        return {
            restrict: 'E',
            template: `
                <div class="form-control">
                    <input ng-show="!disabled" ng-model="url" ng-change="onChange()" ng-focus="onFocus()" ng-blur="onBlur()" />
                    <a ng-href="{{url}}" target="_blank">
                        <span>{{display || 'View'}}</span>
                    </a>
                </div>
            `,
            require: 'ngModel',
            replace: true,
            scope: {
                ngBlur: '&',
                ngFocus: '&'
            },
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                $attrs.$observe('display', function (val) { $scope.display = val; });
                $attrs.$observe('disabled', function (val) { $scope.disabled = val; });

                $scope.onChange = function () {
                    ngModelCtrl.$setViewValue($scope.url);
                };
                $scope.onFocus = function () {
                    _.isFunction($scope.ngFocus) && $scope.ngFocus();
                };
                $scope.onBlur = function () {
                    _.isFunction($scope.ngBlur) && $scope.ngBlur();
                };
                ngModelCtrl.$render = function () {
                    $scope.url = ngModelCtrl.$viewValue;
                };
            }
        };
    }
})(window.angular, window._);
